import React from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import StudentActivity from "@components/student_activity"

const CourseActivityPage = () => {
  const { courseActivity } = data
  return (
    <Layout type="app">
      <SEO title={courseActivity.title} />
      <StudentActivity />
    </Layout>
  )
}

export default CourseActivityPage
