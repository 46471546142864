import React from "react"
import "./style.scss"
import { Headline1A } from "@components/_atoms/Text"

const AppGreeting = ({ data, theme }) => {
  return (
    <div className="app-greeting" data-theme={theme}>
      <div className="greeting">
        <Headline1A className="heading-spacing">{data.header}</Headline1A>
      </div>
    </div>
  )
}

export default AppGreeting
