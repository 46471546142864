import React, { Component } from "react"
import "./style.scss"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import Courses from "@components/_molecules/learning_courses"
import CourseList from "@components/_molecules/list_courses"
import BackPage from "@components/_molecules/back_parent_dashboard"
import { courseActivity } from "../../data/pledge"
import AppGreeting from "@components/_molecules/app_greeting"
class StudentActivity extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { courseActivity } = data
    return (
      <div className="listed-page">
        <div className="heading-size">
          <BackPage data={courseActivity.heading} />
          <AppGreeting data={courseActivity.heading} theme="orange" />
        </div>
        <div className="student-activity">
          <div className="rows">
            <div className="spacing">
              <Courses />
            </div>
            <div className="sizing">
              <CourseList />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default StudentActivity
