import React, { Component } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "./react-tabs.scss"
import { Link } from "gatsby"
import "./style.scss"
import * as headers from "@data/headers"
import * as errors from "@services/errors"
import axios from "axios"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import { courseActivity } from "../../../data/pledge"

class CourseList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      courseActivityData: []
    }
  }
  componentDidMount = () => {
    this.coursesCompletedList()
  }
  coursesCompletedList = () => {
    let _this = this // needed to avoid losing context during axios/fetch call
    let courseCompletedListQuery = {
      query: `query {
        user {
          beneficiaries {
            userCourses(isCompleted: true) {
              nodes {
                course {
                  title
                }
                rewardType
                rewardAmount
                completedAt
              }
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
              totalCount
            }
          }
        }
      }`
    }
    const options = {
      method: "POST",
      data: JSON.stringify(courseCompletedListQuery),
      headers: headers.getHeaders(),
      url: headers.api
    }
    axios(options)
      .then(res => {
        if (res.data.errors) {
          _this.errorHandler(res.data.errors[0].message)
        } else {
          _this.setState({
            courseActivityData:
              res.data.data.user.beneficiaries[0].userCourses.nodes
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  errorHandler = err => {
    this.setState({ generalError: errors.messaging(err) })
  }
  formatDate = date => {
    var options = {
      year: "numeric",
      month: "short",
      day: "numeric"
    }

    return new Date(date).toLocaleDateString("en-US", options)
  }
  render() {
    const { data } = this.props
    const { courseActivityData } = this.state
    const courseLine = (value, index) => (
      <div className="course-line" key={index}>
        <img alt="Bank" src="/images/pledge/bank.svg" />
        <div className="entry">
          <div className="institution">
            <p>{value.course.title}</p>
            <p className="date">{this.formatDate(value.completedAt)}</p>
          </div>
          <div>
            <p>
              {value.rewardType === "usd"
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                  }).format(value.rewardAmount)
                : "🥑 " + parseInt(value.rewardAmount)}
            </p>
          </div>
        </div>
      </div>
    )
    return (
      <div className="course-list">
        <div>
          <p className="title">History</p>
          {courseActivityData.map((value, index) => courseLine(value, index))}
        </div>
      </div>
    )
  }
}
export default CourseList
