import React, { Component } from "react"
import "./style.scss"
import { Link } from "gatsby"
import * as headers from "@data/headers"
import * as errors from "@services/errors"
import axios from "axios"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import Loading from "../loading_state"

class Courses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      courseTotal: "",
      generalError: "",
      loading: true
    }
  }
  componentDidMount = () => {
    this.coursesDone()
  }

  coursesDone = () => {
    let _this = this // needed to avoid losing context during axios/fetch call
    let courseTotalQuery = {
      query: `query{
          user {
          beneficiaries {
            userCourses(isCompleted: true) {
              totalCount
            }
          }
        }
      }`
    }
    const options = {
      method: "POST",
      data: JSON.stringify(courseTotalQuery),
      headers: headers.getHeaders(),
      url: headers.api
    }
    axios(options)
      .then(res => {
        if (res.data.errors) {
          _this.errorHandler(res.data.errors[0].message)
        } else {
          let userData = res.data.data.user
          _this.setState({
            courseTotal: userData.beneficiaries.length ? userData.beneficiaries[0].userCourses.totalCount : 0,
            loading: false
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  errorHandler = err => {
    this.setState({ generalError: errors.messaging(err) })
  }
  render() {
    return (
      <div className="courses container-app">
        <p className="title">COURSES ACTIVITY</p>
        <Loading loading={this.state.loading} className="total-courses">
          <Link to="/app/course-activity">
            {this.state.courseTotal > 1 || this.state.courseTotal === 0
              ? this.state.courseTotal + " courses completed"
              : this.state.courseTotal + " course completed"}
          </Link>
          <GeneralSubmitMessage error={this.state.generalError} />
        </Loading>
      </div>
    )
  }
}
export default Courses
